import React, { useState } from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
// Components
import { ButtonNoLink } from "../button/button.component";
import { FormInput, FormInputBooking } from "../input/input.component";

// Styles
import {
  base,
  imageStyles,
  animations,
  containers,
  AnimatePresence,
} from "../../styles/base/_allBase.styles";
const { StyleP, SmallP, XSmallP } = base;
const { ButtonContainer } = containers;
const { ImgCover, SmallImage, WhatsAppLogo } = imageStyles;
const { motionHoverLarge } = animations;
// Animations
import {
  BoxBeCustom,
  Label,
  PopUpGuest,
  Icon,
  Bold,
  SlimGridRow,
  Row,
  RowPop,
  Col,
} from "./booking-form.styles.js";
// Functions
import { callAll, createEventOptions } from "../../lib/libFunctions";

const BookingForm = () => {
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const router = useRouter();
  const { slug } = router.query;

  const handleBooking = () => {
    let link = "https://secure.guestaps.com/";
    let merchant_id = "bonibeach";
    if (checkIn && checkOut) {
      const promo = promoCode ? promoCode : "promo_code_empty";
      location.href = `${link}${merchant_id}/hotel-filter-redirect/${checkIn}/${checkOut}/${promo}?guest=${adults}-${children}-${infants}`;
    } else {
      // For improved accessibility, we could set aria-invalid here or focus on the missing field.
      if (!checkIn) {
        document.getElementById("check-in").style.borderColor = "#dc3545";
        document
          .getElementById("check-in")
          .setAttribute("aria-invalid", "true");
      }
      if (!checkOut) {
        document.getElementById("check-out").style.borderColor = "#dc3545";
        document
          .getElementById("check-out")
          .setAttribute("aria-invalid", "true");
      }
    }
  };

  function handleSendEventClick(slug) {
    const eventName = "booking_click_banner";
    const eventOptions = createEventOptions(slug);

    gtag("event", eventOptions[eventName].event, {
      event_category: eventOptions[eventName].category,
      event_label: eventOptions[eventName].label,
      page_path: eventOptions[eventName].page,
    });

    gtag("event", "conversion", {
      send_to: "AW-11381423161/iri1COixhvIYELn4irMq",
    });
  }

  return (
    <BoxBeCustom>
      <Row>
        <div>
          <Label htmlFor="check-in">
            <XSmallP>Check in:</XSmallP>
          </Label>
          <FormInputBooking
            type="date"
            name="check-in"
            id="check-in"
            value={checkIn}
            onChange={(e) => setCheckIn(e.target.value)}
            aria-required="true"
          />
        </div>
        <div>
          <Label htmlFor="check-out">
            <XSmallP>Check out:</XSmallP>
          </Label>
          <FormInputBooking
            type="date"
            name="check-out"
            id="check-out"
            value={checkOut}
            onChange={(e) => setCheckOut(e.target.value)}
            aria-required="true"
          />
        </div>
        <div>
          <Label htmlFor="guest-dropdown">
            <XSmallP>Guest:</XSmallP>
          </Label>
          {/* Make the div interactive: button or similar element. 
					    Add aria-expanded and aria-controls for the popup. */}
          <FormInputBooking
            as="button"
            type="button"
            id="guest-dropdown"
            onClick={() => setOpen(!isOpen)}
            aria-haspopup="dialog"
            aria-expanded={isOpen}
            aria-controls="pop-up-guest"
            style={{ textAlign: "left" }}
          >
            <SmallP>
              <span id="adult-guest">{adults}</span> Adults,{" "}
              <span id="children-guest">{children}</span> Children,{" "}
              <span id="infant-guest">{infants}</span> Infants
            </SmallP>
          </FormInputBooking>
        </div>
        <div>
          <Label htmlFor="promo-code">
            <XSmallP>Promo code:</XSmallP>
          </Label>
          <FormInputBooking
            type="text"
            className="input-be"
            name="promo-code"
            placeholder="Promo code"
            id="promo-code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            aria-label="Enter promo code if you have one"
          />
        </div>
        <div>
          <ButtonNoLink
            type="button"
            onClick={callAll(handleBooking, () => handleSendEventClick(slug))}
            aria-label="Proceed to booking page"
          >
            Book Now
          </ButtonNoLink>
        </div>
      </Row>
      <AnimatePresence>
        {isOpen && (
          <PopUpGuest
            {...animations.fadeInOutAnimation}
            id="pop-up-guest"
            role="dialog"
            aria-modal="false"
            aria-label="Select number of guests"
          >
            <div>
              <RowPop>
                <Col size={6}>
                  <StyleP>Adult</StyleP>
                  <SmallP>Ages 17 & above</SmallP>
                </Col>
                <Col size={6}>
                  <SlimGridRow>
                    <Icon
                      onClick={() => setAdults(adults > 1 ? adults - 1 : 1)}
                      aria-label="Decrease number of adults"
                      role="button"
                      tabIndex={0}
                    >
                      -
                    </Icon>
                    <Bold
                      id="adult-amount"
                      aria-label={`Number of adults: ${adults}`}
                    >
                      {adults}
                    </Bold>
                    <Icon
                      onClick={() => setAdults(adults + 1)}
                      aria-label="Increase number of adults"
                      role="button"
                      tabIndex={0}
                    >
                      +
                    </Icon>
                  </SlimGridRow>
                </Col>
              </RowPop>
            </div>
            <div>
              <RowPop>
                <Col>
                  <StyleP>Children</StyleP>
                  <SmallP>Ages 2-16</SmallP>
                </Col>
                <Col>
                  <SlimGridRow>
                    <Icon
                      onClick={() =>
                        setChildren(children > 0 ? children - 1 : 0)
                      }
                      aria-label="Decrease number of children"
                      role="button"
                      tabIndex={0}
                    >
                      -
                    </Icon>
                    <Bold
                      id="children-amount"
                      aria-label={`Number of children: ${children}`}
                    >
                      {children}
                    </Bold>
                    <Icon
                      onClick={() => setChildren(children + 1)}
                      aria-label="Increase number of children"
                      role="button"
                      tabIndex={0}
                    >
                      +
                    </Icon>
                  </SlimGridRow>
                </Col>
              </RowPop>
            </div>
            <div>
              <RowPop>
                <Col>
                  <StyleP>Infants</StyleP>
                  <SmallP>Under 2</SmallP>
                </Col>
                <Col>
                  <SlimGridRow>
                    <Icon
                      onClick={() => setInfants(infants > 0 ? infants - 1 : 0)}
                      aria-label="Decrease number of infants"
                      role="button"
                      tabIndex={0}
                    >
                      -
                    </Icon>
                    <Bold
                      id="infant-amount"
                      aria-label={`Number of infants: ${infants}`}
                    >
                      {infants}
                    </Bold>
                    <Icon
                      onClick={() => setInfants(infants + 1)}
                      aria-label="Increase number of infants"
                      role="button"
                      tabIndex={0}
                    >
                      +
                    </Icon>
                  </SlimGridRow>
                </Col>
              </RowPop>
            </div>
          </PopUpGuest>
        )}
      </AnimatePresence>
    </BoxBeCustom>
  );
};

export default BookingForm;
