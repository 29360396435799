import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Section } from "../section/section.component.jsx";
import {
  GridCardSection,
  ReviewCard,
  StarRating,
  ReviewText,
  ReviewContent,
  ReadMoreButton,
  ReviewHeader,
  ProfileImage,
  ReviewerName,
  ReviewDate,
} from "./reviews-styles.js";
import Stars from "../stars/star-component.jsx";
import DragSlider from "../drag-slider/drag-slider.jsx";
import { useParallaxInView } from "../../lib/hooks";
import { SmallP, StyleP } from "../../styles/base/_base.styles.js";

function ReviewsSection({ reviews = [] }) {
  // const [reviews, setReviews] = useState([]);
  const [expandedReview, setExpandedReview] = useState(null);
  const { y, ref } = useParallaxInView();

  const handleExpandClick = (reviewId) => {
    setExpandedReview(expandedReview === reviewId ? null : reviewId);
  };

  return (
    <Section cursorsize={"small"} size={"auto"}>
      <GridCardSection ref={ref} style={{ zIndex: 10, position: "relative" }}>
        <DragSlider>
          {reviews.map((review, i) => (
            <ReviewCard key={i} style={{ zIndex: 10, position: "relative" }}>
              <StarRating>
                <Stars rating={review.rating} />
              </StarRating>
              <ReviewHeader>
                <ProfileImage
                  src={review.profile_photo_url}
                  alt={review.author_name}
                />
                <div>
                  <StyleP>{review.author_name}</StyleP>
                  <SmallP>{review.relative_time_description}</SmallP>
                </div>
              </ReviewHeader>
              <ReviewContent>
                <StyleP>
                  {expandedReview === review.id
                    ? review.text
                    : `${review.text.substring(0, 300)}...`}
                </StyleP>
                <ReadMoreButton onClick={() => handleExpandClick(review.id)}>
                  {expandedReview === review.id ? "Read less" : "Read more"}
                </ReadMoreButton>
              </ReviewContent>
            </ReviewCard>
          ))}
        </DragSlider>
      </GridCardSection>
    </Section>
  );
}

export default ReviewsSection;
