import React from "react";
import { useRouter } from "next/router";
import { sanitySrcUrl, sanityVideoUrl } from "../../lib/libFunctions";
// Styles
import {
  base,
  imageStyles,
  animations,
  Image,
} from "../../styles/base/_allBase.styles";
const { H1, H1TextDivIndex, WhatsAppDiv, BookingFormDiv } = base;
const { ImgCover, SmallImage, WhatsAppLogo } = imageStyles;
const { motionHoverLarge } = animations;
import { VideoContainer, Video } from "./section-one-video.styles";
// Assets
import scrollDown from "../../assets/icons/arrow-scroll.png";
import WhatsaAppLogo from "../../assets/svg/whatsapp.svg";
// Animations

// Components
import BookingForm from "../booking-form/booking-form";

import { createEventOptions } from "../../lib/libFunctions.js";

export default function SectionVideo({ video }) {
  const url = video ? sanityVideoUrl(video) : "";

  const router = useRouter();
  const { slug } = router.query;

  function handleSendEventClick(slug) {
    const eventName = "whatsapp_click";
    const eventOptions = createEventOptions(slug);

    gtag("event", eventOptions[eventName].event, {
      event_category: eventOptions[eventName].category,
      event_label: eventOptions[eventName].label,
      page_path: eventOptions[eventName].page,
    });

    gtag("event", "conversion", {
      send_to: "AW-11381423161/iri1COixhvIYELn4irMq",
    });
  }

  return (
    <VideoContainer>
      <Video autoPlay muted loop>
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </Video>
      <H1TextDivIndex>
        <h1>{video?.header}</h1>
      </H1TextDivIndex>
      <BookingFormDiv>
        <BookingForm />
      </BookingFormDiv>
      <WhatsAppDiv {...motionHoverLarge}>
        <a
          name="Call or message Boni Beach Lombok WhatsApp"
          href=" https://wa.me/+628113800355"
          onClick={() => handleSendEventClick(slug)}
          alt="Contact us via whatsapp button"
        >
          <WhatsaAppLogo
            aria-label="WhatsApp Link Button"
            alt={"Whatsapp logo to open chat with Boni Beach Hotel Lombok"}
          />
        </a>
      </WhatsAppDiv>
    </VideoContainer>
  );
}

// export { SectionOne };
