import React from "react";
import ReactGA from "react-ga4";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
  resetCookieConsentValue,
  allConsentGranted,
} from "react-cookie-consent";
import Link from "next/link";
import { H4, StyleP } from "../../styles/base/_base.styles";

const CookieConsentComp = () => {
  const [consent, setConsent] = React.useState(getCookieConsentValue());

  React.useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      setConsent(true);
      handleAcceptCookie();
    } else if (isConsent === "false") {
      setConsent(false);
      handleDeclineCookie();
    }
  }, [consent]);

  const allConsentGranted = () => {
    window.gtag("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
      ad_personalization: "granted",
      ad_user_data: "granted",
    });
  };

  const handleAcceptCookie = () => {
    setConsent(true);
    ReactGA.initialize("G-2NKNGZ8YMZ");
    ReactGA.initialize("AW-11381423161");
    ReactGA.send("pageview");
    allConsentGranted();
  };

  const handleDeclineCookie = () => {
    setConsent(false);
    Cookies.remove("_ga");
    Cookies.remove("_ga_2NKNGZ8YMZ");
    Cookies.remove("_gat");
    Cookies.remove("ad_storage");
    Cookies.remove("ad_user_data");
    Cookies.remove("ad_personalization");
    Cookies.remove("analytics_storage");
  };
  return (
    <CookieConsent
      acceptOnScroll={true}
      acceptOnScrollPercentage={2}
      // enableDeclineButton
      // onDecline={handleDeclineCookie}
      // declineButtonText={'Reject All'}
      flipButtons={true}
      debug={false}
      style={{ background: "black", height: "auto", margin: "auto auto" }}
      buttonStyle={{ background: "white", color: "black", fontSize: "13px" }}
      expires={50}
      buttonText={"Accept All"}
      overlay
    >
      <H4 style={{ color: "white", fontSize: "1.75rem" }}>
        We value your privacy
      </H4>
      <StyleP style={{ fontSize: "1.5rem", color: "white" }}>
        We use cookies to for measurement and analytics purposes. By using our
        website and services you agree to our use of cookies as described in our
        privacy policy.
      </StyleP>

      <Link href={"privacy-policy"}>
        <StyleP
          style={{
            cursor: "pointer",
            color: "white",
            fontSize: "1.5rem",
            textDecoration: "underline",
          }}
        >
          Cookie Policy{" "}
        </StyleP>
      </Link>
    </CookieConsent>
  );
};

export { CookieConsentComp };
