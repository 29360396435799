import React, { useRef, useEffect, useState } from "react";
import { motion, useSpring, useDragControls } from "framer-motion";
import { Box, Slider, ControlsDiv, Control } from "./drag-slider.styles";

const DragSlider = ({ children }) => {
  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderChildrenWidth, setSliderChildrenWidth] = useState(0);
  const [sliderConstraints, setSliderConstraints] = useState(0);
  const [currentPercent, setCurrentPercent] = useState(0);
  const ref = useRef(null);
  const dragControls = useDragControls();
  const x = useSpring(0, { stiffness: 300, damping: 50 });

  // Update slider dimensions and constraints on mount and resize
  useEffect(() => {
    const calcDimensions = () => {
      if (ref.current) {
        setSliderChildrenWidth(ref.current.scrollWidth);
        setSliderWidth(ref.current.clientWidth);
        setSliderConstraints(ref.current.scrollWidth - ref.current.clientWidth);
      }
    };

    calcDimensions();
    window.addEventListener("resize", calcDimensions);
    return () => window.removeEventListener("resize", calcDimensions);
  }, [ref]);

  // Update current scroll percentage when `x` changes
  useEffect(() => {
    const unsubscribe = x.onChange((value) => {
      const clampedValue = Math.min(0, Math.max(value, -sliderConstraints));
      setCurrentPercent(-clampedValue / sliderChildrenWidth);
    });

    return () => unsubscribe();
  }, [sliderChildrenWidth, sliderConstraints, x]);

  // Helper to check if an element is in view with >50% threshold
  function isInView(i) {
    const elementWidth = sliderChildrenWidth / children.length; // Width of each child
    const currentStart = currentPercent * sliderChildrenWidth; // Start of the visible area
    const currentEnd = currentStart + sliderWidth; // End of the visible area

    const elementStart = i * elementWidth; // Start position of the current element
    const elementEnd = elementStart + elementWidth; // End position of the current element

    // Calculate the amount of the element that is in view
    const visibleStart = Math.max(currentStart, elementStart); // Start of the overlap
    const visibleEnd = Math.min(currentEnd, elementEnd); // End of the overlap
    const visibleWidth = Math.max(0, visibleEnd - visibleStart); // Overlap width

    // Check if more than 50% of the element is visible
    const isMoreThanHalfVisible = visibleWidth > elementWidth * 0.5;

    return isMoreThanHalfVisible;
  }

  // Set position on click of control
  function setPosition(i) {
    const elementWidth = sliderChildrenWidth / children.length;
    const targetPosition = -i * elementWidth;

    if (targetPosition >= -sliderConstraints) {
      x.set(targetPosition);
    } else {
      x.set(-sliderConstraints);
    }
  }

  return (
    <div>
      <Box>
        <Slider
          ref={ref}
          style={{ x }}
          drag="x"
          dragControls={dragControls}
          dragListener={false}
          initial={{ x: 0 }}
          dragConstraints={{ left: -sliderConstraints, right: 0 }}
          dragTransition={{ bounceStiffness: 50, bounceDamping: 10 }}
        >
          {React.Children.map(children, (child, i) =>
            React.cloneElement(child, {
              onPointerDown: (e) => dragControls.start(e),
              onTouchStart: (e) => dragControls.start(e),
              onTouchMove: (e) => dragControls.start(e),
            })
          )}
        </Slider>
      </Box>
      <ControlsDiv>
        {children.map((child, i) => (
          <Control
            key={child.key || i}
            highlight={isInView(i).toString()}
            onClick={() => setPosition(i)}
          />
        ))}
      </ControlsDiv>
    </div>
  );
};

export default DragSlider;
