import React, { useState, useEffect, useRef, Children } from "react";
import {
  useCursorPosition,
  useCursorChange,
  useWrap,
  useCarousel,
  useParallaxInView,
} from "../../lib/hooks";
import { sanitySrcUrl } from "../../lib/libFunctions";
// Styles
import {
  base,
  styled,
  AnimatePresence,
  imageStyles,
  motion,
  colors,
  breakpoints,
  typo,
  useCycle,
  useScroll,
  useInView,
} from "../../styles/base/_allBase.styles";
import {
  Container,
  Cursor,
  ButtonDiv,
  HeaderContainer,
  StyledLeftArrow,
} from "./carousel.boni-day.styles";
const { StyleP, H1, H2, H3, H4 } = base;
const { SmallImage, ImgCover } = imageStyles;
// Animations
import {
  cursorDirectionAnimation,
  fadeInAndOutVariants,
  fadeInOutAnimation,
  inViewOptions,
} from "../../styles/base/_animations.styles";
// Components
import { BoniDayDisplay } from "../boni-day-display/boni-day-display";
import { Section } from "../section/section.component";
// Assets
import SunRise from "../../assets/logo/sunrise.png";

function generateCarouselItems(imageArray) {
  return imageArray.map((item, i) => {
    return (
      <motion.div
        key={item._key}
        cycle={true.toString()}
        {...fadeInOutAnimation}
        // style={{ height: "100%", width: "100%" }}
      >
        <ImgCover
          style={{ cursor: "none" }}
          src={sanitySrcUrl(item.photo)}
          alt={item.photo.alt}
          fill
        />
      </motion.div>
    );
  });
}

export default function CarouselBoniDay({ children, imageArray, ...props }) {
  const { cursorYSpring, cursorXSpring } = useCursorPosition();
  const [size, setSize, visible, setVisible, rotate, rotation] =
    useCursorChange();
  const imageCarouselItems = generateCarouselItems(imageArray);
  const [imageIndex, showHide, changeImage] = useCarousel(imageCarouselItems);
  const childArray = React.Children.toArray(children);

  const timeoutRef = useRef(null);
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  useEffect(() => {
    resetTimeout();
    if (true) {
      timeoutRef.current = setTimeout(() => changeImage("next"), 5000);
    } else {
      return;
    }
    return () => {
      resetTimeout();
    };
  }, [imageIndex]);

  const { y, ref, isInView } = useParallaxInView(200);

  return (
    <Section size={"small"} nocursor={true}>
      <Container
        ref={timeoutRef}
        onMouseEnter={setVisible}
        onMouseLeave={setVisible}
      >
        <HeaderContainer ref={ref} style={{ y, opacity: isInView ? 1 : 0 }}>
          <SmallImage
            alt={"Boni Beach Lombok Sunrise Logo"}
            style={{ height: "4rem" }}
            src={SunRise}
          />
          <H2
            style={{
              padding: "2rem 1rem 1rem 1rem",
              color: colors.warmPalette.primary,
            }}
            color={colors.warmPalette.primary}
            primary
          >
            The Perfect Day at Boni Beach Hotel Lombok
          </H2>
        </HeaderContainer>
        <AnimatePresence initial={false} mode="wait">
          {showHide && (
            <motion.div>
              {/* {React.cloneElement(childArray[imageIndex], { ...props })} */}
              {imageCarouselItems[imageIndex]}
            </motion.div>
          )}
        </AnimatePresence>
        <Cursor
          visible={visible.toString()}
          animate={
            rotate && visible ? "rotate" : !size && visible ? "small" : "exit"
          }
          variants={cursorDirectionAnimation}
          style={{
            translateX: cursorXSpring,
            translateY: cursorYSpring,
          }}
        >
          <StyledLeftArrow viewBox="0 0 74 74">
            <path
              d="M76.89962,74.61675l-23.31585-11.771L16.66254,44.20605,8.11891,39.8928v5L31.41868,33.21941l37.0749-18.57485,8.42688-4.22194a10.88288,10.88288,0,0,0,.025-2.5,12.00257,12.00257,0,0,1-.025-2.5L53.6207,17.096,16.54579,35.67086,8.11891,39.8928a25.67041,25.67041,0,0,0,0,5l23.31585,11.771L68.356,75.30349l8.54363,4.31326a15.30352,15.30352,0,0,1,.025-2.5c0-.27253.20362-2.38459-.025-2.5Z"
              transform="translate(-7.99664 -5.42262)"
            />
          </StyledLeftArrow>
        </Cursor>
        <ButtonDiv
          left={true.toString()}
          onClick={() => changeImage("prev")}
        ></ButtonDiv>
        <ButtonDiv
          onMouseEnter={rotation}
          onMouseLeave={rotation}
          right={true.toString()}
          onClick={() => changeImage("next")}
        ></ButtonDiv>

        <BoniDayDisplay
          data={imageArray}
          onMouseEnter={setVisible}
          onMouseLeave={setVisible}
          showHide={showHide}
          imageIndex={imageIndex}
          onClick={changeImage}
          style={{ gridArea: "animation" }}
        />
      </Container>
    </Section>
  );
}

export { CarouselBoniDay };
