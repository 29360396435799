import { motion } from "framer-motion";
import styled from "styled-components";
import * as breakpoints from "../../styles/abstracts/_breakpoints";
import * as colors from "../../styles/abstracts/_variables.styles";
// Breakpoints
const {
  xxSmallDevices,
  xxSmallDevicesLandscape,
  extraSmallDevices,
  extraSmallDevicesLandscape,
  mediumDevices,
  mediumDevicesLandscape,
  largeDevices,
  extraLargeDevices,
  xxLargeDevices,
} = breakpoints;

export const Box = styled(motion.div)`
  overflow-x: scroll;
  height: 85%;
  margin: 0rem 0rem;
  width: 85vw;
`;

export const Slider = styled(motion.div)`
  display: flex;
  flex-shrink: 0;
  cursor: grab;
  ${"" /* gap: 3rem; */}
  height: 100%;
  justify-content: "space-between";
  width: 100%;
`;

export const ControlsDiv = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding: 1rem 0.5rem;
  gap: 0.25rem;
  @media ${xxSmallDevices} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }

  @media ${mediumDevices} {
  }
  @media ${largeDevices} {
    padding: 2rem 1rem;
    gap: 1rem;
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const Control = styled(motion.div)`
  height: 1.25rem;
  width: 1.25rem;
  cursor: pointer;
  background-color: ${(props) =>
    props.highlight === "true"
      ? colors.warmPalette.secondary
      : colors.warmPalette.tertiary};
  border-radius: 50%;
  display: inline-block;
  padding: 0rem 0.25rem;
  margin: 0rem 0.25rem;

  @media ${largeDevices} {
    height: 2rem;
    width: 2rem;
    padding: 0rem 1rem;
    margin: 0rem 1rem;
  }
`;
