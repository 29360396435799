import styled, { css, keyframes } from 'styled-components';
import { motion } from 'framer-motion';
// colours
import * as colors from '../../styles/abstracts/_variables.styles';
import * as breakpoints from '../../styles/abstracts/_breakpoints';
// Breakpoints
const { xxSmallDevices, xxSmallDevicesLandscape, extraSmallDevices, extraSmallDevicesLandscape, smallDevices, mediumDevices, mediumDevicesLandscape, largeDevices, extraLargeDevices, xxLargeDevices } =
	breakpoints;

export const name = styled(motion.html)`
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${smallDevices} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;

export const BoxBeCustom = styled.div`
	${'' /* border: 1px solid #ddd; */}
	padding: 1.5rem;
	margin: 1rem;
	background-color: ${colors.warmPalette.neutralLight};
	display: none;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${smallDevices} {
	}
	@media ${mediumDevices} {
		display: block;
		padding: 1rem;
	}
	@media ${mediumDevicesLandscape} {
		display: block;
	}
	@media ${largeDevices} {
		display: block;
		padding: 1rem;
	}
	@media ${extraLargeDevices} {
		display: block;
		padding: 1.5rem;
	}
	@media ${xxLargeDevices} {
		display: block;
		padding: 1.5rem;
	}
`;

export const Label = styled.label`
	display: block;
	margin-bottom: 8px;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${smallDevices} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;

export const PopUpGuest = styled(motion.div)`
	background-color: ${colors.warmPalette.neutralLight};
	margin: 10px;
	padding: 8px 24px;
	${'' /* border: 1px solid #dee2e6; */}
	border-radius: 6px;
	width: 310px;
	${'' /* box-shadow: 0 4px 6px 0 rgb(0 0 0 / 7%); */}
	display: block;
	position: absolute;
	left: 35%;
	bottom: 20%; /* Position above the element */
	transform: translateY(-10px); /* Adjust to your liking */
	z-index: 9;
	transition: 0.3s ease-in-out;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${smallDevices} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;

export const Icon = styled.div`
	border: 1px solid ${colors.font_black};
	border-radius: 100%;
	width: 35px;
	height: 35px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${smallDevices} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;

export const Bold = styled.p`
	font-weight: 600;
	font-size: 18px;
	text-align: center;
	padding: 0rem 1rem;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${smallDevices} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;

export const SlimGridRow = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 0.25rem;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${smallDevices} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 0rem;
	justify-content: space-around;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${smallDevices} {
	}
	@media ${mediumDevices} {
		gap: 1rem;
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
		gap: 1rem;
	}
	@media ${extraLargeDevices} {
		gap: 1rem;
	}
	@media ${xxLargeDevices} {
		gap: 1rem;
	}
`;

export const RowPop = styled.div`
	display: flex;
	justify-content: space-around;
	${
		'' /* align-items: center;
	flex-wrap: wrap; */
	}
	gap: 1rem;
	margin: 0rem;
	justify-content: space-around;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${smallDevices} {
	}
	@media ${mediumDevices} {
		gap: 0rem;
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
		gap: 1rem;
	}
	@media ${extraLargeDevices} {
		gap: 1rem;
	}
	@media ${xxLargeDevices} {
		gap: 1rem;
	}
`;

export const Col = styled.div`
	width: 100%;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${smallDevices} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;
