import styled from "styled-components";
import { motion } from "framer-motion";
import Img from "next/legacy/image";

import * as colors from "../../styles/abstracts/_variables.styles";
import * as breakpoints from "../../styles/abstracts/_breakpoints";
import * as typo from "../../styles/abstracts/_typography.styles";

// Breakpoints
const {
  xxSmallDevices,
  xxSmallDevicesLandscape,
  extraSmallDevices,
  extraSmallDevicesLandscape,
  mediumDevices,
  mediumDevicesLandscape,
  largeDevices,
  extraLargeDevices,
  xxLargeDevices,
} = breakpoints;

export const GridCardSection = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  width: 86vw;
  height: auto;
  paddind-top: 2rem;
  margin: auto auto;
  margin-top: 2rem;
  @media ${xxSmallDevices} {
    // grid-template-rows: 100vh 50vh;
  }
  @media ${extraSmallDevices} {
  }
  @media ${mediumDevices} {
    grid-template-rows: auto 30vh;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    grid-template-rows: 40vh 30vh;
    width: 85vw;
  }
  @media ${extraLargeDevices} {
    grid-template-rows: 100vh 50vh;
  }
  @media ${xxLargeDevices} {
  }
`;

export const TextContainer = styled(motion.div)`
  position: relative;
  height: auto;
  margin: 0rem;
  background: inherit;
  @media ${xxSmallDevices} {
    margin: 0rem;
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;
